<template>
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 7.99967C2 7.63148 2.29848 7.33301 2.66667 7.33301H13.3333C13.7015 7.33301 14 7.63148 14 7.99967C14 8.36786 13.7015 8.66634 13.3333 8.66634H2.66667C2.29848 8.66634 2 8.36786 2 7.99967Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 3.99967C2 3.63148 2.29848 3.33301 2.66667 3.33301H13.3333C13.7015 3.33301 14 3.63148 14 3.99967C14 4.36786 13.7015 4.66634 13.3333 4.66634H2.66667C2.29848 4.66634 2 4.36786 2 3.99967Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 11.9997C2 11.6315 2.29848 11.333 2.66667 11.333H13.3333C13.7015 11.333 14 11.6315 14 11.9997C14 12.3679 13.7015 12.6663 13.3333 12.6663H2.66667C2.29848 12.6663 2 12.3679 2 11.9997Z"
    />
  </svg>
</template>

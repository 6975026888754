<template>
  <div
    class="relative z-[99] before:absolute before:bottom-0 before:block before:h-px before:w-full before:content-['']"
    :class="
      green
        ? 'bg-green-900 text-white before:bg-gradient-to-r before:from-green-800 before:via-green-500 before:to-green-800'
        : 'bg-grey-100 before:bg-gradient-to-r before:from-grey-50 before:via-grey-300 before:to-grey-50 lg:border-b-0'
    "
  >
    <!-- Notices -->
    <!-- <div
      class="text-neutral-900 z-[97] bg-lemon-50 p-2 text-center text-xs font-light text-grey-700 md:text-sm"
    >
      We've encountered an issue across our customer service and sales call
      centres, causing longer-than-usual call times. You can still reach us
      through
      <BtnLiveChat
        :slot="true"
        styles="underline hover:no-underline font-semibold"
        >LiveChat</BtnLiveChat
      >.
    </div> -->

    <NavSegmentModal
      :active-segment="activeSegment"
      :is-open="segmentModalOpen"
      @close="closeModal"
    />

    <nav
      aria-label="Category menu"
      class="container flex items-center justify-between !px-4 sm:!px-8"
    >
      <button
        ref="openModalButton"
        class="flex h-full items-center gap-1 py-3 text-xs font-semibold uppercase lg:hidden"
        :class="green ? 'text-white' : 'text-grey-600 hover:text-grey-900'"
        @click="openModal"
      >
        {{ activeSegment }}
        <IconChevronDown
          class="h-3 w-3 shrink-0 transform fill-current text-current"
          :class="segmentModalOpen ? 'rotate-180' : 'rotate-0'"
        />
      </button>

      <ul class="hidden gap-8 lg:flex">
        <li v-for="(segment, id) in segmentData" :key="id">
          <NuxtLink
            class="group relative flex h-full items-center gap-1 py-3 text-xs font-bold uppercase italic tracking-wider no-underline after:absolute after:-bottom-[0.1875rem] after:block after:h-1 after:w-full after:rounded-full after:bg-none after:content-['']"
            :class="[
              green ? 'text-white' : 'text-grey-600 hover:text-grey-900',
              {
                'after:bg-green-500': activeSegment === id && green,
              },
              {
                'after:bg-green-800': activeSegment === id && !green,
              },
            ]"
            :to="segment.link"
            :external="segment.link.includes('http')"
            :aria-current="activeSegment === id"
          >
            {{ segment.name }}

            <IconCheck
              v-if="activeSegment === id"
              class="h-3 w-3 fill-current"
            />

            <IconArrowRight
              v-else
              class="h-3 w-3 fill-current motion-safe:transform motion-safe:transition-transform motion-safe:group-hover:translate-x-0.5"
            />
          </NuxtLink>
        </li>
      </ul>

      <NuxtLink
        v-if="activeSegment === 'wholesale'"
        class="group flex h-full items-center gap-1 py-3 text-xs font-semibold uppercase no-underline xs:text-sm lg:font-bold lg:italic lg:tracking-wide"
        :class="green ? 'text-white' : 'text-grey-600 hover:text-grey-900'"
        to="/wholesale/become-a-partner/#contact"
      >
        <IconMessage
          class="hidden h-3 w-3 shrink-0 fill-current text-current min-[220px]:block lg:h-4 lg:w-4"
        />
        Contact us
      </NuxtLink>

      <NuxtLink
        v-else
        class="flex items-center gap-2 py-2.5 text-xs font-semibold no-underline hover:underline sm:text-sm lg:text-base"
        :class="green ? 'text-white' : 'text-grey-700'"
        :to="activeSegmentPhone.sales.url"
      >
        <IconCall
          class="hidden h-4 w-4 shrink-0 fill-current text-current min-[220px]:block"
        />
        {{ activeSegmentPhone.sales.text }}
      </NuxtLink>
    </nav>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  activeSegment: Segment;
  green: boolean;
}>();

const { segmentData } = getNavData();
const phoneNumbers = getPhoneNumbers();

const openModalButton = ref();
const activeSegmentPhone = computed(() => data[props.activeSegment]);
const segmentModalOpen = ref(false);

const data = {
  personal: phoneNumbers.personalPhone,
  business: phoneNumbers.businessPhone,
  enterprise: phoneNumbers.enterprisePhone,
  wholesale: phoneNumbers.wholesalePhone,
};

const closeModal = () => {
  segmentModalOpen.value = false;
  openModalButton.value.focus();
};
const openModal = () => (segmentModalOpen.value = true);

watch(segmentModalOpen, () => {
  if (segmentModalOpen.value) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
});
</script>

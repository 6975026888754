<template>
  <div class="w-full bg-grey-50">
    <div class="container grid grid-cols-12 px-8 xl:flex">
      <div
        class="col-span-12 border-b-2 border-grey-300 py-6 xl:w-72 xl:shrink-0 xl:border-b-0 xl:border-r-2 xl:py-12 xl:pr-6"
      >
        <div class="tag !mb-2">{{ data?.heading }}</div>
        <div
          class="mb-4 text-base font-bold"
          :class="{ uppercase: isUppercase }"
        >
          {{ data?.title }}
        </div>
        <div class="rte hidden xl:block" v-html="data?.description"></div>

        <BtnText
          v-if="data?.ctaTitle && data?.ctaLink"
          :link="true"
          :to="data?.ctaLink"
          arrow="right"
          class="!justify-start !fill-green-800 !text-sm !text-green-800"
        >
          {{ menuData?.ctaTitle }}
        </BtnText>

        <NuxtLink
          v-if="data?.proCta"
          to="https://www.aussiebroadband.com.au/internet/ultrafast-nbn/"
          class="group relative z-10 mt-4 hidden w-full items-end justify-between gap-2 overflow-hidden rounded-xl p-4 no-underline xl:flex"
        >
          <div class="w-full">
            <div class="tag-on-dark">
              Introducing <span class="sr-only">Pro</span>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 12"
                fill="none"
                class="mt-2 h-8 w-auto max-w-full shrink-0"
              >
                <path
                  d="M5.39997 0.263674H1.19683C0.535149 0.263674 0 0.793805 0 1.44659C0 2.10061 0.536402 2.62954 1.19683 2.62954H5.43259C6.15317 2.62954 6.7923 3.13244 6.9076 3.83601C7.05297 4.72167 6.34367 5.50575 5.47145 5.50575H1.94624C1.6367 5.50575 1.38604 5.75348 1.38604 6.05944V11.4589C1.38604 11.6261 1.52263 11.7611 1.69181 11.7611H3.47509C3.64427 11.7611 3.78086 11.6261 3.78086 11.4589V7.87661C3.78086 7.87661 3.78215 7.87289 3.78466 7.87289H5.47016C6.06041 7.87289 6.62061 7.73911 7.12189 7.50252C8.46531 6.86708 9.38389 5.48223 9.31497 3.90415C9.22725 1.8566 7.4715 0.263674 5.39747 0.263674H5.39997ZM26.0451 0.0010587C22.6013 -0.0621141 19.7966 2.71007 19.8605 6.11396C19.9207 9.32215 22.5687 11.9395 25.8145 11.9989C29.2582 12.0621 32.0628 9.28993 31.9989 5.88604C31.9388 2.67785 29.2908 0.0605154 26.0451 0.0010587ZM25.7405 9.62809C23.8733 9.53519 22.3544 8.03514 22.2604 6.18827C22.1501 4.03172 23.9397 2.26167 26.1215 2.37067C28.0414 2.46605 29.579 4.04659 29.6066 5.94549C29.6367 8.0401 27.8759 9.73337 25.7405 9.62685V9.62809ZM16.4118 7.50624C16.4118 7.50624 16.4118 7.50252 16.4131 7.50128C17.754 6.8646 18.6713 5.48099 18.6023 3.90539C18.5146 1.85661 16.7589 0.264914 14.6849 0.264914H10.9441C10.2824 0.264914 9.74734 0.795045 9.74734 1.44783C9.74734 2.10185 10.2837 2.63078 10.9441 2.63078H14.7563C15.6285 2.63078 16.3378 3.41486 16.1925 4.30052C16.0772 5.00409 15.438 5.50699 14.7175 5.50699H11.8677C11.206 5.50699 10.6709 6.03715 10.6709 6.68994C10.6709 7.34396 11.2073 7.87289 11.8677 7.87289H13.8402C13.8402 7.87289 13.8428 7.87289 13.844 7.87413L16.0496 11.5196C16.1398 11.6694 16.3027 11.7599 16.4794 11.7599H18.5422C18.7377 11.7599 18.858 11.5493 18.7578 11.3845L16.4105 7.505L16.4118 7.50624Z"
                  fill="#FFFFFF"
                />
              </svg>
            </div>
            <p class="my-0 text-base text-white">
              High-upload nbn® plans for your home.
            </p>
          </div>
          <div
            class="flex size-9 shrink-0 items-center justify-center rounded-full bg-green-400 group-hover:bg-green-300"
          >
            <IconArrowRight class="size-4 fill-grey-900" />
          </div>
          <img
            src="/img/residential/pro/pro-background-1-1920x1280.webp"
            alt=""
            width="1920"
            height="1280"
            class="pointer-events-none absolute left-0 top-0 -z-10 size-full object-cover object-left"
          />
        </NuxtLink>
      </div>

      <div
        class="grid auto-cols-fr gap-6 py-12 pr-8 xl:w-full xl:px-6"
        :class="[
          {
            'grid-cols-2':
              data?.children.length === 1 && data?.children[0].cols === 1,
          },
          data?.quickLinks ? 'col-span-8' : 'col-span-12',
        ]"
      >
        <div
          v-for="col in data?.children"
          :key="col.title"
          class="row-start-1"
          :class="{ 'col-span-2 grid auto-rows-min grid-cols-2': col.cols > 1 }"
        >
          <div
            v-if="col.title"
            class="tag"
            :class="{ 'col-span-2': col.cols === 2 }"
          >
            {{ col.title }}
          </div>

          <ul
            class="grid gap-4"
            :class="[
              { 'col-span-2 grid-flow-col-dense grid-cols-2': col.cols === 2 },
              { 'grid-rows-[repeat(2,minmax(0,max-content))]': col.rows === 2 },
              { 'grid-rows-[repeat(3,minmax(0,max-content))]': col.rows === 3 },
              { 'grid-rows-[repeat(4,minmax(0,max-content))]': col.rows === 4 },
            ]"
          >
            <li v-for="link in col.links" :key="link.name">
              <NuxtLink
                :to="link.link"
                :external="link.link.includes('http')"
                class="group flex h-full w-full items-start gap-4 rounded-lg p-3 no-underline hover:bg-grey-100"
              >
                <component
                  :is="link.icon"
                  v-if="link.icon"
                  class="mt-0.5 h-6 w-6 shrink-0 fill-green-800 text-green-800 no-underline"
                />
                <div class="w-full">
                  <div class="mb-1 flex items-center justify-between gap-4">
                    <div
                      class="!mb-0 grow-0 text-base font-bold uppercase italic tracking-wide"
                    >
                      {{ link.name }}
                    </div>
                  </div>

                  <p class="text-sm text-grey-600">
                    {{ link.desc }}
                  </p>
                </div>
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>

      <div
        v-if="data?.quickLinks"
        class="col-span-4 border-l-2 border-grey-300 py-12 pl-6 xl:w-72 xl:shrink-0"
      >
        <div class="tag">{{ data.quickLinks.title }}</div>
        <ul class="gap-0 text-base font-normal">
          <li v-for="link in data.quickLinks.links" :key="link.link">
            <NuxtLink
              :to="link.link"
              :external="link.link.includes('http')"
              class="group relative flex gap-2 py-1 no-underline"
            >
              <span
                class="relative pl-3 before:absolute before:left-0 before:top-0 before:block before:h-full before:w-0.5 before:rounded-full before:bg-green-800 before:opacity-0 before:content-[''] group-hover:before:opacity-100"
              >
                {{ link.name }}
                <div
                  v-if="link.pro"
                  class="inline-flex items-center gap-1.5 rounded-full border border-jag-100/75 bg-jag-1000 px-2 py-1.5"
                >
                  <strong class="sr-only">Pro</strong>
                  <svg
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 12"
                    fill="none"
                    class="h-2.5 w-auto shrink-0"
                  >
                    <path
                      d="M5.39997 0.263674H1.19683C0.535149 0.263674 0 0.793805 0 1.44659C0 2.10061 0.536402 2.62954 1.19683 2.62954H5.43259C6.15317 2.62954 6.7923 3.13244 6.9076 3.83601C7.05297 4.72167 6.34367 5.50575 5.47145 5.50575H1.94624C1.6367 5.50575 1.38604 5.75348 1.38604 6.05944V11.4589C1.38604 11.6261 1.52263 11.7611 1.69181 11.7611H3.47509C3.64427 11.7611 3.78086 11.6261 3.78086 11.4589V7.87661C3.78086 7.87661 3.78215 7.87289 3.78466 7.87289H5.47016C6.06041 7.87289 6.62061 7.73911 7.12189 7.50252C8.46531 6.86708 9.38389 5.48223 9.31497 3.90415C9.22725 1.8566 7.4715 0.263674 5.39747 0.263674H5.39997ZM26.0451 0.0010587C22.6013 -0.0621141 19.7966 2.71007 19.8605 6.11396C19.9207 9.32215 22.5687 11.9395 25.8145 11.9989C29.2582 12.0621 32.0628 9.28993 31.9989 5.88604C31.9388 2.67785 29.2908 0.0605154 26.0451 0.0010587ZM25.7405 9.62809C23.8733 9.53519 22.3544 8.03514 22.2604 6.18827C22.1501 4.03172 23.9397 2.26167 26.1215 2.37067C28.0414 2.46605 29.579 4.04659 29.6066 5.94549C29.6367 8.0401 27.8759 9.73337 25.7405 9.62685V9.62809ZM16.4118 7.50624C16.4118 7.50624 16.4118 7.50252 16.4131 7.50128C17.754 6.8646 18.6713 5.48099 18.6023 3.90539C18.5146 1.85661 16.7589 0.264914 14.6849 0.264914H10.9441C10.2824 0.264914 9.74734 0.795045 9.74734 1.44783C9.74734 2.10185 10.2837 2.63078 10.9441 2.63078H14.7563C15.6285 2.63078 16.3378 3.41486 16.1925 4.30052C16.0772 5.00409 15.438 5.50699 14.7175 5.50699H11.8677C11.206 5.50699 10.6709 6.03715 10.6709 6.68994C10.6709 7.34396 11.2073 7.87289 11.8677 7.87289H13.8402C13.8402 7.87289 13.8428 7.87289 13.844 7.87413L16.0496 11.5196C16.1398 11.6694 16.3027 11.7599 16.4794 11.7599H18.5422C18.7377 11.7599 18.858 11.5493 18.7578 11.3845L16.4105 7.505L16.4118 7.50624Z"
                      fill="#FAFAAA"
                    ></path>
                  </svg>
                </div>
              </span>
              <IconArrowRight
                class="ml-auto mt-1 h-4 w-4 shrink-0 transform fill-grey-800 group-hover:translate-x-1 motion-safe:transition-transform"
              />
            </NuxtLink>
          </li>
          <li v-if="data?.quickLinks.cta">
            <NuxtLink
              :to="data?.quickLinks.cta.link"
              :external="data?.quickLinks.cta.link.includes('http')"
              class="group relative mt-4 flex items-center gap-2 py-1 no-underline"
            >
              <span
                class="relative pl-3 text-sm font-bold uppercase italic tracking-wider text-green-800 before:absolute before:left-0 before:top-0 before:block before:h-full before:w-0.5 before:rounded-full before:bg-green-800 before:opacity-0 before:content-[''] group-hover:before:opacity-100"
              >
                {{ data?.quickLinks.cta.name }}
              </span>
              <IconArrowRight
                class="ml-auto h-4 w-4 shrink-0 transform fill-green-800 group-hover:translate-x-1 motion-safe:transition-transform"
              />
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  menuData: NavChild["content"];
  isUppercase: boolean;
  menuId?: string;
}>();

const data = props.menuData;
</script>

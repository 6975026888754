<template>
  <div
    class="left-0 top-0 z-[100] flex h-full w-full items-center justify-center px-4"
    :class="isOpen ? 'fixed lg:hidden' : 'hidden'"
    tabindex="-1"
    @keydown.tab="handleTab"
    @keydown.esc="handleEsc"
  >
    <div
      ref="modalContainer"
      class="relative z-10 w-full rounded-lg bg-grey-50 px-6 py-6 text-left"
    >
      <button
        ref="closeButton"
        class="absolute right-5 top-4 flex h-6 w-6 items-center justify-center"
        aria-label="Close"
        @click="$emit('close')"
      >
        <IconClose class="h-5 w-5 shrink-0 fill-grey-900 text-grey-900" />
      </button>

      <div class="mb-4 pr-8">
        <div class="tag !mb-2 !text-sm">Select property type</div>
        <p class="mb-0 text-sm text-grey-700">
          View products and information about the property type that best suits
          your needs.
        </p>
      </div>

      <ul>
        <li
          v-for="(segment, id) in segmentData"
          :key="id"
          class="group border-b border-grey-300 last:border-b-0"
        >
          <NuxtLink
            :to="segment.link"
            :external="segment.link.indexOf('http') > -1"
            class="flex items-center justify-between gap-4 py-5 no-underline"
            :class="activeSegment === id ? '' : ''"
            @click="$emit('close')"
          >
            <div
              class="mb-1 text-sm font-bold uppercase italic tracking-wide text-grey-600"
            >
              {{ segment.nameAbbr ?? segment.name }}
              <span v-if="activeSegment === id" class="font-normal"
                >(current)</span
              >
            </div>

            <IconChevronRight class="h-4 w-4 shrink-0 text-grey-600" />
          </NuxtLink>
        </li>
      </ul>
    </div>

    <div
      class="absolute h-full w-full bg-grey-900/25 backdrop-blur-sm"
      role="button"
      aria-label="Close"
      @click="$emit('close')"
    ></div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  activeSegment: Segment;
  isOpen: boolean;
}>();

const emit = defineEmits(["close"]);
const { segmentData } = getNavData();
const closeButton = ref();
const modalContainer = ref();

const focusableElements = computed(() => {
  if (!modalContainer.value) return;
  return modalContainer.value.querySelectorAll(
    'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])',
  );
});

const firstFocusable = computed(() => focusableElements.value[0]);
const lastFocusable = computed(
  () => focusableElements.value[focusableElements.value.length - 1],
);

watch(
  () => props.isOpen,
  (newVal) => newVal && nextTick(() => closeButton.value.focus()),
);

const handleEsc = () => emit("close");
const handleTab = (e: KeyboardEvent) =>
  restrictTab(e, firstFocusable.value, lastFocusable.value);
</script>

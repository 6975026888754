<template>
  <div>
    <NuxtLoadingIndicator class="loader" />
    <NavNavigation :green="greenNav" />
    <main id="main" :class="customStyle">
      <slot />
    </main>
    <NavFooterNav />
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  error?: {
    statusCode: number;
    statusMessage: string;
  };
}>();

const route = useRoute();
const customStyle = ref("");
const greenNav = ref(false);

const getCustomStyle = (path: string) => {
  const contentStylePaths = [
    "blog",
    "help-centre",
    "media-centre",
    "complaint",
    "privacy-policy",
  ];
  const enterpriseStylePaths = ["enterprise", "wholesale"];
  const pathArr = path.split("/");

  if (pathArr.some((x) => contentStylePaths.includes(x))) {
    customStyle.value = "!content";
  } else if (pathArr.some((x) => enterpriseStylePaths.includes(x))) {
    customStyle.value = "!enterprise";
  } else {
    customStyle.value = "";
  }
};

const updateGreenNav = (path: string) => {
  const nonGreenNavPaths = [
    "enterprise",
    "wholesale",
    "blog",
    "help-centre",
    "media-centre",
    "about-us",
    "careers",
    "disaster-support",
  ];

  const pathArr = path.split("/");
  greenNav.value = !pathArr.some((x) => nonGreenNavPaths.includes(x));
};

getCustomStyle(route.path);
updateGreenNav(route.path);

watch(
  () => route.path,
  (newRoute, oldRoute) => {
    if (newRoute === oldRoute) return;

    getCustomStyle(newRoute);
    updateGreenNav(newRoute);
  },
);

const metaStore = useMetaStore();
const pageTitle = computed(() =>
  props.error
    ? `${props.error.statusCode} - ${props.error.statusMessage} | Aussie Broadband`
    : metaStore.pageTitle,
);
const metaTitle = computed(() => metaStore.metaTitle);
const description = computed(() => metaStore.metaDescription);

useHead({
  title: pageTitle,
  link: [
    { rel: "icon", type: "image/svg+xml", href: "/img/logos/favicon.svg" },
    {
      rel: "canonical",
      href: `https://www.aussiebroadband.com.au${route.path}`,
    },
  ],
  meta: [{ name: "theme-color", content: "#13322B" }],
});

useServerSeoMeta({
  title: metaTitle,
  ogTitle: metaTitle,
  description: description,
  ogDescription: description,
  ogImage:
    "https://assets.aussiebroadband.com.au/web/images/misc/abb-og-image.png",
  twitterTitle: metaTitle,
  twitterDescription: description,
  twitterImage:
    "https://assets.aussiebroadband.com.au/web/images/misc/abb-og-image.png",
  twitterSite: "@Aussie_BB",
});
</script>

<style>
.loader {
  @apply !bg-gradient-to-r from-green-500 to-green-800;
}

.page-enter-active,
.page-leave-active {
  transition: opacity 0.3s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>

<template>
  <svg
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
  >
    <path
      d="M0.833008 6L5.23726 8.87277V15.2167L7.68407 16.8127V19L0.833008 14.5312V6Z"
    />
    <path
      d="M10.6202 8.87277L6.21599 6V14.5312L13.0671 19V16.8127L10.6202 15.2167V8.87277Z"
    />
    <path
      d="M11.599 6L16.0032 8.87277V15.2167L18.45 16.8127V19L11.599 14.5312V6Z"
    />
    <path d="M23.833 10.4688L16.9819 6V14.5312L23.833 19V10.4688Z" />
  </svg>
</template>

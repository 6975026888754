<template>
  <svg
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
  >
    <path
      d="M9.96501 0.876253C9.965 0.668457 10.1334 0.5 10.3412 0.5H11.0022C13.8665 0.5 16.1885 2.89475 16.1885 5.84884V10.6408L16.1885 10.6628V12.8023L16.1885 12.8108V13.3372C16.1885 15.1097 14.7953 16.5465 13.0768 16.5465C11.3582 16.5465 9.96499 15.1097 9.96499 13.3372V11.1977L9.96502 11.1893V10.9302C9.96502 10.1917 9.38453 9.59302 8.66846 9.59302C7.95238 9.59302 7.37189 10.1917 7.37189 10.9302V13.3562C7.38184 16.3016 9.69998 18.686 12.5581 18.686C13.9146 18.686 15.1494 18.149 16.0736 17.2698L16.3712 16.9628C17.2237 16.0097 17.7444 14.7362 17.7444 13.3372V5.31395C17.7444 4.72314 18.2088 4.24419 18.7817 4.24419C19.3545 4.24419 19.8189 4.72314 19.8189 5.31395V13.3372C19.8189 13.3606 19.8188 13.384 19.8186 13.4074L22.8952 12.0691C23.0597 11.9975 23.2514 12.0354 23.3763 12.1642C23.5383 12.3313 23.5383 12.5969 23.3763 12.7639L17.8654 18.4476C17.752 18.5727 17.6345 18.6939 17.5132 18.8108L17.0659 19.2721L17.0312 19.2362C15.7983 20.232 14.2455 20.8256 12.5581 20.8256C10.5427 20.8256 8.7191 19.9787 7.40358 18.6112C7.6111 20.1023 7.86853 22.4468 11.0931 22.6357C11.328 22.6494 11.5209 22.8386 11.5209 23.0739C11.5209 23.3092 11.3301 23.5 11.0948 23.5H10.4836C7.61932 23.5 5.29735 21.1052 5.29735 18.1512L5.29738 13.3596C5.29736 13.3522 5.29735 13.3447 5.29735 13.3372L5.29735 10.6628C5.29735 10.5494 5.31447 10.4401 5.34618 10.3375C5.61896 8.69969 7.0026 7.45349 8.66846 7.45349C10.5303 7.45349 12.0395 9.01008 12.0395 10.9302V12.8023L12.0395 12.8108V13.3372C12.0395 13.928 12.5039 14.407 13.0768 14.407C13.6496 14.407 14.114 13.928 14.114 13.3372L14.114 10.6442C14.1043 7.69867 11.7861 5.31395 8.92777 5.31395C6.06347 5.31395 3.7415 7.70871 3.7415 10.6628V17.0814C3.7415 17.6722 3.27711 18.1512 2.70425 18.1512C2.13139 18.1512 1.66699 17.6722 1.66699 17.0814V10.6628C1.66699 6.52708 4.91775 3.17442 8.92777 3.17442C10.9432 3.17442 12.7668 4.02129 14.0823 5.3888C13.8749 3.89911 13.1416 1.46601 10.3423 1.26568C10.1351 1.25085 9.96502 1.08404 9.96501 0.876253Z"
    />
  </svg>
</template>

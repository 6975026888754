<template>
  <div
    tabindex="-1"
    class="left-0 top-0 z-[1000] flex h-full w-full items-start justify-center px-8 py-[5vh] md:px-[10vw] md:py-[10vh]"
    :class="isOpen ? 'fixed' : 'hidden'"
    @keydown.tab="handleTab"
    @keydown.esc="emit('closeSearch')"
  >
    <div
      class="relative z-10 flex max-h-full w-full max-w-screen-md flex-col overflow-hidden rounded-lg bg-grey-50"
    >
      <div
        class="flex w-full shrink-0 overflow-hidden rounded-t-lg border-b border-grey-300 bg-grey-50"
        :class="{ 'rounded-b-lg': !resultsOpen }"
      >
        <label class="shrink-0 py-4 pl-4 pr-2 md:px-4" for="navSearchInput">
          <IconSearch class="h-5 w-5 fill-grey-400" />
          <span class="sr-only">Search</span>
        </label>
        <input
          id="navSearchInput"
          ref="navSearchInput"
          v-model="searchInput"
          type="search"
          name="search"
          role="combobox"
          placeholder="Search..."
          autocomplete="off"
          enterkeyhint="go"
          aria-haspopup="listbox"
          aria-owns="resultsList"
          aria-controls="resultsList"
          :aria-activedescendant="
            highlightedResult >= 0
              ? 'nav-search-result-' + highlightedResult
              : undefined
          "
          :aria-expanded="resultsOpen"
          class="w-full appearance-none bg-transparent py-4 pl-2 text-base text-grey-700 placeholder-grey-400 focus:outline-none md:px-4"
          @input="handleInput"
          @keydown.down.stop.prevent="handleDown"
          @keydown.up.stop.prevent="handleUp"
          @keydown.enter.prevent="handleEnter"
        />

        <button
          type="button"
          class="border-l border-grey-300 bg-grey-100 p-4 text-grey-600 transition-colors hover:bg-grey-300"
          @click="emit('closeSearch')"
        >
          Close
        </button>
      </div>

      <div
        v-if="resultsOpen && searchInput.length > 1"
        class="overflow-y-auto rounded-b-lg"
      >
        <div v-if="loading" class="flex w-full justify-center py-4">
          <IconLoaderSmall class="block h-5 w-5 fill-green-800" />
        </div>
        <div
          v-else-if="searchResults.length === 0"
          id="navResultsList"
          aria-labelledby="navSearchInput"
          class="p-3 lg:p-8"
        >
          No results found for
          <span class="font-medium">'{{ searchQuery }}'</span>.
        </div>

        <ol
          v-else
          id="navResultsList"
          ref="navResultsList"
          role="listbox"
          aria-labelledby="navSearchInput"
          class="z-20"
          :data-insights-index="searchIndex"
        >
          <li
            v-for="(result, index) in searchResults"
            :id="'nav-search-result-' + index"
            :key="result.objectID"
            :ref="(el) => addResultRef(el, index)"
            role="option"
            :aria-selected="index === highlightedResult"
            :data-insights-object-id="result.objectID"
            :data-insights-position="index + 1"
            :data-insights-query-id="searchQueryID"
          >
            <article>
              <NuxtLink
                :to="result.url"
                class="block w-full border-l-[3px] border-transparent p-3 text-left no-underline hover:border-green-800 hover:bg-grey-100 lg:px-4"
                :class="{
                  '!border-green-800 bg-grey-100': index === highlightedResult,
                }"
                @mouseenter="highlightedResult = index"
                @keydown.up.prevent="handleDown"
                @keydown.down.prevent="handleUp"
              >
                <h3 class="!mb-1 max-w-xl !text-base font-semibold normal-case">
                  {{ result.title }}
                </h3>
                <p v-if="result.description" class="mb-2 max-w-xl text-sm">
                  {{ shortDesc(result.description, 200) }}
                  <span class="text-xs italic text-grey-600">(read more)</span>
                </p>
                <p
                  v-if="result.category"
                  class="flex w-max rounded-full bg-grey-100 p-1 text-xs font-medium uppercase tracking-wide text-grey-700"
                  v-html="result.category"
                ></p>
              </NuxtLink>
            </article>
          </li>
          <li
            :id="'nav-search-result-' + searchResults.length"
            :ref="(el) => addResultRef(el, searchResults.length)"
            :aria-selected="highlightedResult === searchResults.length"
            class="px-3 pb-4 lg:px-4"
          >
            <BtnText
              :link="true"
              :url="
                'https://www.aussiebroadband.com.au/search?q=' + searchInput
              "
              :external="true"
              arrow="right"
              :start="true"
              :small="true"
              class="mt-8 !justify-start !fill-green-800 !text-green-800"
              :class="{
                '!border-green-800 bg-grey-100':
                  highlightedResult === searchResults.length,
              }"
            >
              View all results
            </BtnText>
          </li>
        </ol>
      </div>
    </div>
    <button
      ref="backgroundClose"
      class="absolute left-0 top-0 h-full w-full bg-grey-900/25 backdrop-blur-sm"
      aria-label="Close"
      @click="$emit('closeSearch')"
    ></button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits(["closeSearch"]);

const searchIndex = "live_website";

const backgroundClose = ref();
const navSearchInput = ref();

const loading = ref(false);
const resultsOpen = ref(false);
const highlightedResult = ref(0);
const searchInput = ref("");
const searchQuery = ref("");
const searchResults = ref<Hit[] | []>([]);
const searchResultsItems = ref<Element[] | ComponentPublicInstance[]>([]);
const searchQueryID = ref("");
const gtm = useGtm();
const { search } = useAlgoliaSearch(searchIndex);
const gaUserToken = ref("");

onMounted(() => {
  gaUserToken.value = updateGAUserToken(gtm);
});

watch(searchInput, (newVal) => {
  if (newVal.length < 2) {
    resultsOpen.value = false;
    loading.value = true;
  }
});

watch(searchQuery, async (newVal) => {
  if (newVal.length < 2) {
    searchResults.value = [];
    return;
  }

  loading.value = true;

  const response = await search({
    query: newVal,
    requestOptions: {
      hitsPerPage: 20,
      responseFields: ["hits"],
      clickAnalytics: true,
      userToken: gaUserToken.value,
    },
  });

  gtm?.trackEvent({
    event: "Hits Viewed",
  });

  loading.value = false;
  searchResults.value = response.hits as Hit[];
  if (response.queryID) {
    searchQueryID.value = response.queryID;
  }
});

watch(searchResults, () => scrollToResult(0));

watch(
  () => props.isOpen,
  (newVal) => newVal && nextTick(() => navSearchInput.value.focus()),
);

const addResultRef = (
  el: Element | ComponentPublicInstance | null,
  index: number,
) => {
  if (el) searchResultsItems.value[index] = el;
};

const scrollToResult = (index: number) => {
  const activeEl: HTMLElement = searchResultsItems.value[index] as HTMLElement;
  if (!activeEl) return;
  activeEl.scrollIntoView({ block: "nearest" });
};

const handleInput = debounce(() => {
  if (searchInput.value.length > 2) {
    resultsOpen.value = true;
  }
  searchQuery.value = searchInput.value;
  highlightedResult.value = 0;
}, 300);

const handleUp = () => {
  if (highlightedResult.value === 0) {
    return;
  }
  highlightedResult.value--;
  scrollToResult(highlightedResult.value);
};

const handleDown = () => {
  if (highlightedResult.value === searchResults.value.length) return;
  if (searchInput.value.length < 2) return;

  // Still open to show the 'no results found' message
  if (searchResults.value.length === 0) {
    resultsOpen.value = true;
    return;
  }

  if (resultsOpen.value) {
    highlightedResult.value++;
    scrollToResult(highlightedResult.value);
    return;
  }

  highlightedResult.value = 0;
  resultsOpen.value = true;
};

const handleEnter = async () => {
  if (searchResults.value.length === 0) return;

  const url =
    highlightedResult.value === searchResults.value.length
      ? "https://www.aussiebroadband.com.au/search/?q=" + searchInput.value
      : searchResults.value[highlightedResult.value].url;

  await navigateTo(url, { external: true });
};

const handleTab = (e: KeyboardEvent) =>
  restrictTab(e, navSearchInput.value, backgroundClose.value);

const shortDesc = (desc: string, descLength: number) =>
  truncate(desc, descLength);
</script>

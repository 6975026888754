<template>
  <a class="sr-only" href="#main">Skip to main content</a>

  <NavSegmentHeader :green="green" :active-segment="activeSegment" />

  <div class="sticky left-0 top-0 z-[97] lg:relative" @keydown.esc="resetNav">
    <div
      class="relative z-10 w-full"
      :class="
        green
          ? 'bg-green-800 shadow-md lg:shadow-none'
          : 'border-b border-grey-300 bg-grey-50 lg:border-b-0'
      "
    >
      <nav
        aria-label="Primary menu"
        class="container flex items-center justify-between gap-2 px-8 py-3 lg:py-4"
      >
        <NuxtLink
          :to="segmentData.personal.link"
          :external="true"
          class="w-full max-w-[9rem] lg:hidden"
        >
          <span class="sr-only">Home</span>
          <img
            class="h-auto w-full"
            :src="
              green
                ? '/img/logos/aussie-broadband/abb-logo-dark.svg'
                : '/img/logos/aussie-broadband/abb-logo-light.svg'
            "
            alt="Aussie Broadband logo"
            width="145"
            height="40"
          />
        </NuxtLink>

        <div class="hidden items-center lg:flex">
          <NuxtLink
            :to="segmentData.personal.link"
            :external="true"
            class="mr-12 w-36 shrink-0"
          >
            <span class="sr-only">Home</span>
            <img
              class="h-auto w-full"
              :src="
                green
                  ? '/img/logos/aussie-broadband/abb-logo-aaw-dark.svg'
                  : '/img/logos/aussie-broadband/abb-logo-aaw-light.svg'
              "
              alt="Aussie Broadband logo"
              width="145"
              height="51"
            />
          </NuxtLink>

          <ul>
            <li
              v-for="(segment, id) in segmentData"
              :key="id"
              :class="{ hidden: activeSegment !== id }"
            >
              <ul class="flex gap-6">
                <li v-for="item in segment.children" :key="item.id">
                  <template v-if="item.content">
                    <button
                      :id="item.id + 'Button'"
                      type="button"
                      class="group flex items-center gap-2 py-2 text-sm font-bold uppercase italic tracking-wider no-underline"
                      :class="
                        desktopCurrentMenu === item.name
                          ? activeClass
                          : inactiveClass
                      "
                      :aria-expanded="desktopCurrentMenu === item.name"
                      :aria-controls="item.id + 'Menu'"
                      @click="openDesktopMenu(item)"
                    >
                      {{ item.name }}
                      <IconChevronDown
                        class="h-3 w-3 shrink-0 transform fill-current"
                        :class="{
                          'rotate-180': desktopCurrentMenu === item.name,
                        }"
                      />
                    </button>

                    <NavDesktopMenu
                      v-show="
                        item.name === desktopCurrentMenu && activeSegment === id
                      "
                      :id="item.id + 'Menu'"
                      :aria-labelledby="item.id + 'Button'"
                      class="absolute left-0 top-full"
                      :class="{ 'border-t border-grey-300': !green }"
                      :menu-id="item.id"
                      :menu-data="item.content"
                      :is-uppercase="isUppercase"
                    />
                  </template>

                  <NuxtLink
                    v-else
                    :to="item.link"
                    :external="isExternal(item.link!)"
                    class="group flex items-center gap-2 py-2 text-sm font-bold uppercase italic tracking-wider no-underline"
                    :class="
                      desktopCurrentMenu === item.name
                        ? activeClass
                        : inactiveClass
                    "
                  >
                    {{ item.name }}
                    <IconArrowRight class="h-3 w-3 shrink-0 fill-current" />
                  </NuxtLink>
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <div class="flex items-center gap-2 sm:gap-4 lg:gap-8">
          <button
            id="loginButton"
            type="button"
            class="group hidden items-center gap-2 text-base font-bold uppercase italic lg:flex"
            :class="
              desktopCurrentMenu === 'Login' ? activeClass : inactiveClass
            "
            :aria-expanded="desktopCurrentMenu === 'Login'"
            aria-controls="loginMenu"
            @click="openDesktopMenu(loginData)"
          >
            Login

            <IconChevronDown
              class="h-3 w-3 shrink-0 transform fill-current"
              :class="{ 'rotate-180': desktopCurrentMenu === 'Login' }"
            />
          </button>

          <NavDesktopMenu
            v-show="desktopCurrentMenu === 'Login'"
            id="loginMenu"
            aria-labelledby="loginButton"
            class="absolute left-0 top-full w-full"
            :class="{ 'border-t border-grey-300': !green }"
            :menu-data="loginData.content"
            :is-uppercase="isUppercase"
          />

          <button
            ref="searchButton"
            type="button"
            class="group flex min-w-[1.5rem] items-center justify-center gap-2 py-2 text-base font-bold uppercase italic no-underline"
            :class="searchIsOpen ? activeClass : inactiveClass"
            @click="handleSearchClick"
          >
            <span class="sr-only xl:not-sr-only">Search</span>
            <IconSearch class="h-5 w-5 shrink-0 fill-current" />
          </button>

          <NuxtLink
            to="https://www.aussiebroadband.com.au/cart/"
            :external="true"
            class="group flex items-center gap-2 py-2 text-base font-bold uppercase italic no-underline"
            :class="searchIsOpen ? activeClass : inactiveClass"
          >
            <span class="sr-only xl:not-sr-only">Cart</span>
            <span
              class="relative flex h-6 w-6 shrink-0 items-center justify-center"
              :class="{
                'before:absolute before:-right-1 before:-top-0.5 before:h-2.5 before:w-2.5 before:rounded-full before:bg-[#dc2626]':
                  hasCartItems,
              }"
            >
              <IconCart class="h-5 w-5 shrink-0 fill-current" />
            </span>
          </NuxtLink>

          <button
            id="mobileMenuButton"
            ref="burgerButton"
            type="button"
            class="group z-[101] flex items-center rounded-full text-sm focus:outline-none focus:ring-2 lg:hidden"
            :class="
              green
                ? 'fill-white focus:ring-white'
                : 'hover:bg-grey-50 focus:ring-grey-200'
            "
            :aria-expanded="mobileIsOpen"
            aria-controls="mobileMenu"
            @click="handleBurgerClick"
          >
            <span class="sr-only">Toggle mobile menu</span>
            <span class="flex h-6 w-6 shrink-0 items-center justify-center">
              <IconHamburger
                v-show="!mobileIsOpen"
                class="h-5 w-5"
                :class="green ? 'fill-white' : 'fill-grey-900'"
              />
              <IconClose
                v-show="mobileIsOpen"
                class="h-5 w-5"
                :class="green ? 'fill-white' : 'fill-grey-900'"
              />
            </span>
          </button>
        </div>
      </nav>

      <Transition name="blur-bg">
        <div
          v-if="mobileIsOpen || searchIsOpen"
          class="blurred-bg absolute left-0 top-full h-screen w-full bg-grey-900/25 backdrop-blur-sm lg:hidden"
          @click="
            {
              mobileIsOpen = false;
              searchIsOpen = false;
            }
          "
        ></div>
      </Transition>

      <Transition name="sidebar">
        <NavSidebar
          v-show="mobileIsOpen"
          id="mobileMenu"
          :mobile-is-open="mobileIsOpen"
          aria-labelledby="mobileMenuButton"
          @close="handleSidebarClose"
        />
      </Transition>
    </div>

    <div
      v-if="desktopIsOpen"
      class="blurred-bg fixed left-0 top-0 z-[9] hidden h-screen w-full bg-grey-900/25 backdrop-blur-sm lg:block"
      @click="resetNav"
    ></div>
  </div>

  <NavSearch
    :is-open="searchIsOpen"
    index="test_live_website"
    query-params=""
    filter-type=""
    post-priority=""
    @close-search="handleSearchClose"
  />
</template>

<script setup lang="ts">
import ls from "localstorage-slim";

const props = defineProps<{ green: boolean }>();
const { segmentData, loginData } = getNavData();
const route = useRoute();

const burgerButton = ref();
const searchButton = ref();
const desktopCurrentMenu = ref("");
const mobileIsOpen = ref(false);
const desktopIsOpen = ref(false);
const searchIsOpen = ref(false);
const hasCartItems = ref(false);

// const segmentInUrl = computed<Segment | false>(() => {
//   return (
//     Object.keys(segmentData).find((segment) => {
//       return route.fullPath.includes(segment);
//     }) || false
//   );
// });

const segmentInUrl = computed<Segment | false>(() => {
  return (
    (Object.keys(segmentData) as Array<keyof typeof segmentData>).find(
      (segment) => {
        return route.fullPath.includes(segment);
      },
    ) || false
  );
});

const activeSegment = computed<Segment>(() => {
  if (segmentInUrl.value) return segmentInUrl.value;
  if (route.fullPath.indexOf("case-studies") > -1) return "business";
  return "personal";
});

const isUppercase = computed<boolean>(() =>
  ["personal", "business"].includes(activeSegment.value),
);

const activeClass = computed(() =>
  props.green ? "text-lemon-200" : "text-green-800",
);

const inactiveClass = computed(() =>
  props.green
    ? "text-white hover:text-lemon-200"
    : "text-grey-700 hover:text-green-800",
);

const handleResize = (e: MediaQueryListEvent) => {
  if (!mobileIsOpen.value) return;

  if (e.matches) {
    document.body.classList.remove("overflow-hidden");
  } else {
    document.body.classList.add("overflow-hidden");
  }
};

onMounted(() => {
  checkCartExists();

  if (import.meta.client) {
    const mediaQueryList = window.matchMedia("(min-width: 1024px)");
    mediaQueryList.addEventListener("change", handleResize);
  }
});

watch(
  () => route.fullPath,
  (newVal, oldVal) => {
    if (newVal === oldVal) return;
    resetNav();
    checkCartExists();
  },
);

watch(mobileIsOpen, () => {
  if (mobileIsOpen.value) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
});

watch(searchIsOpen, () => {
  if (searchIsOpen.value) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
});

const openDesktopMenu = (item: NavChild) => {
  if (item.name !== "search") searchIsOpen.value = false;

  if (!item.content?.children) {
    desktopIsOpen.value = false;
    if (typeof item.link === "string") {
      window.location.href = item.link;
    }
    return;
  }

  if (item.name === desktopCurrentMenu.value) {
    desktopIsOpen.value = !desktopIsOpen.value;
  } else {
    desktopIsOpen.value = true;
  }

  if (desktopIsOpen.value) {
    desktopCurrentMenu.value = item.name;
  } else {
    desktopCurrentMenu.value = "";
  }
};

const checkCartExists = () => {
  const cartItems = ls.get("aussie:items-in-cart");
  hasCartItems.value = !!cartItems && (cartItems as number) > 0;
};

const isExternal = (link: string) => link.indexOf("http") > -1;
const handleSearchClick = () => (searchIsOpen.value = true);
const handleBurgerClick = () => (mobileIsOpen.value = !mobileIsOpen.value);
const handleSidebarClose = () => {
  mobileIsOpen.value = false;
  burgerButton.value.focus();
};

const handleSearchClose = () => {
  searchIsOpen.value = false;
  searchButton.value.focus();
};

const resetNav = () => {
  desktopIsOpen.value = false;
  mobileIsOpen.value = false;
  searchIsOpen.value = false;
  desktopCurrentMenu.value = "";
};
</script>

<style scoped>
* {
  touch-action: manipulation;
}

@supports (height: 100dvh) {
  .blurred-bg {
    height: 100dvh !important;
  }
}

.sidebar-enter-active,
.sidebar-leave-active {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.sidebar-enter-from,
.sidebar-leave-to {
  transform: translateX(100%);
}

.blur-bg-enter-active,
.blur-bg-leave-active {
  transition: opacity 300ms ease-in-out;
}

.blur-bg-enter-from,
.blur-bg-leave-to {
  opacity: 0;
}

@media (prefers-reduced-motion: reduce) {
  .desktop-enter-active,
  .desktop-leave-active,
  .sidebar-enter-active,
  .sidebar-leave-active .blur-bg-enter-active,
  .blur-bg-leave-active {
    transition: none;
  }
}
</style>

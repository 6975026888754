<template>
  <svg
    viewBox="0 0 25 24"
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.16602 3.00692C3.16602 1.62239 4.2853 0.5 5.66602 0.5H19.666C21.0467 0.5 22.166 1.62239 22.166 3.00692V18.4811C22.166 19.5453 21.4959 20.4936 20.4945 20.8463L13.4433 23.33C12.7809 23.5633 12.0579 23.5563 11.4001 23.3101L4.79188 20.837C3.8142 20.4711 3.16602 19.5347 3.16602 18.4883V3.00692ZM20.166 12.0318H9.2526C8.75291 12.0318 8.34783 11.6257 8.34783 11.1246C8.34783 10.6235 8.75291 10.2173 9.2526 10.2173H20.166V3.00692C20.166 2.73002 19.9422 2.50554 19.666 2.50554H5.66602C5.38987 2.50554 5.16602 2.73002 5.16602 3.00692V5.68098H16.0794C16.5791 5.68098 16.9842 6.08717 16.9842 6.58824C16.9842 7.08931 16.5791 7.49551 16.0794 7.49551H5.16602V14.7537H16.0794C16.5791 14.7537 16.9842 15.1599 16.9842 15.6609C16.9842 16.162 16.5791 16.5682 16.0794 16.5682H5.16602V18.4883C5.16602 18.5173 5.1685 18.5458 5.1733 18.5737C5.20317 18.7473 5.32272 18.895 5.49119 18.9581L12.0994 21.4312C12.3187 21.5132 12.5597 21.5156 12.7805 21.4378L19.8317 18.9541C20.032 18.8836 20.166 18.6939 20.166 18.4811V12.0318Z"
    />
  </svg>
</template>

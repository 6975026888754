<template>
  <div
    v-show="props.visible"
    class="nested-page absolute right-0 top-0 h-full w-full transform overflow-y-scroll border-l border-grey-300 bg-grey-50 px-6 pb-8 pt-6"
    @keydown.tab="handleTab"
  >
    <div
      class="mb-6 flex items-end justify-start gap-2 border-b border-grey-300 pb-6"
    >
      <button
        ref="backButton"
        type="button"
        class="flex items-end justify-start gap-1 text-xs font-medium uppercase italic text-grey-600"
        aria-label="Go back"
        @click="$emit('back')"
      >
        <IconArrowUpLeft class="h-4 w-4 shrink-0 fill-grey-600" />
        {{ menuData.content!.heading }}
      </button>

      <template v-if="menuData.name !== 'Login'">
        <IconChevronRight class="h-3 w-3 self-center fill-grey-600" />

        <div class="text-xs font-medium uppercase italic text-grey-600">
          {{ menuData.name }}
        </div>
      </template>
    </div>

    <div
      v-for="subcategory in menuData.content!.children"
      :key="subcategory.title"
      class="mb-6 last:mb-0"
    >
      <div class="mb-3 text-sm font-semibold text-green-800">
        {{ subcategory.title }}
      </div>

      <ul class="gap-0">
        <li v-for="link in subcategory.links" :key="link.name">
          <NuxtLink
            :to="link.link"
            :external="link.link.indexOf('http') > -1"
            class="flex w-full items-center justify-start gap-3 py-3 text-left text-sm font-medium no-underline"
          >
            <component
              :is="link.icon"
              class="h-5 w-5 shrink-0 fill-green-800 text-green-800"
            />
            {{ link.name }}
            <IconArrowRight
              class="ml-auto h-3.5 w-3.5 shrink-0 fill-grey-600"
            />
          </NuxtLink>
        </li>
      </ul>
    </div>

    <div
      v-if="menuData.content!.quickLinks"
      class="mb-12 mt-6 border-t border-grey-300 pt-6"
    >
      <div class="mb-3 text-sm font-semibold text-green-800">
        {{ menuData.content!.quickLinks.title }}
      </div>

      <ul class="gap-0">
        <li
          v-for="quickLink in menuData.content!.quickLinks.links"
          :key="quickLink.link"
        >
          <NuxtLink
            :to="quickLink.link"
            :external="quickLink.link.indexOf('http') > -1"
            class="flex w-full items-center justify-between gap-3 py-2 text-left text-sm no-underline"
          >
            {{ quickLink.name }}
            <div
              v-if="quickLink.pro"
              class="mr-auto inline-flex items-center gap-1.5 rounded-full border border-jag-100/75 bg-jag-1000 px-2 py-1.5"
            >
              <strong class="sr-only">Pro</strong>
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 32 12"
                fill="none"
                class="h-2.5 w-auto shrink-0"
              >
                <path
                  d="M5.39997 0.263674H1.19683C0.535149 0.263674 0 0.793805 0 1.44659C0 2.10061 0.536402 2.62954 1.19683 2.62954H5.43259C6.15317 2.62954 6.7923 3.13244 6.9076 3.83601C7.05297 4.72167 6.34367 5.50575 5.47145 5.50575H1.94624C1.6367 5.50575 1.38604 5.75348 1.38604 6.05944V11.4589C1.38604 11.6261 1.52263 11.7611 1.69181 11.7611H3.47509C3.64427 11.7611 3.78086 11.6261 3.78086 11.4589V7.87661C3.78086 7.87661 3.78215 7.87289 3.78466 7.87289H5.47016C6.06041 7.87289 6.62061 7.73911 7.12189 7.50252C8.46531 6.86708 9.38389 5.48223 9.31497 3.90415C9.22725 1.8566 7.4715 0.263674 5.39747 0.263674H5.39997ZM26.0451 0.0010587C22.6013 -0.0621141 19.7966 2.71007 19.8605 6.11396C19.9207 9.32215 22.5687 11.9395 25.8145 11.9989C29.2582 12.0621 32.0628 9.28993 31.9989 5.88604C31.9388 2.67785 29.2908 0.0605154 26.0451 0.0010587ZM25.7405 9.62809C23.8733 9.53519 22.3544 8.03514 22.2604 6.18827C22.1501 4.03172 23.9397 2.26167 26.1215 2.37067C28.0414 2.46605 29.579 4.04659 29.6066 5.94549C29.6367 8.0401 27.8759 9.73337 25.7405 9.62685V9.62809ZM16.4118 7.50624C16.4118 7.50624 16.4118 7.50252 16.4131 7.50128C17.754 6.8646 18.6713 5.48099 18.6023 3.90539C18.5146 1.85661 16.7589 0.264914 14.6849 0.264914H10.9441C10.2824 0.264914 9.74734 0.795045 9.74734 1.44783C9.74734 2.10185 10.2837 2.63078 10.9441 2.63078H14.7563C15.6285 2.63078 16.3378 3.41486 16.1925 4.30052C16.0772 5.00409 15.438 5.50699 14.7175 5.50699H11.8677C11.206 5.50699 10.6709 6.03715 10.6709 6.68994C10.6709 7.34396 11.2073 7.87289 11.8677 7.87289H13.8402C13.8402 7.87289 13.8428 7.87289 13.844 7.87413L16.0496 11.5196C16.1398 11.6694 16.3027 11.7599 16.4794 11.7599H18.5422C18.7377 11.7599 18.858 11.5493 18.7578 11.3845L16.4105 7.505L16.4118 7.50624Z"
                  fill="#FAFAAA"
                ></path>
              </svg>
            </div>
            <IconArrowRight class="h-3.5 w-3.5 shrink-0 fill-grey-600" />
          </NuxtLink>
        </li>
        <li v-if="menuData.content!.quickLinks.cta">
          <NuxtLink
            :to="menuData.content!.quickLinks.cta.link"
            :external="
              menuData.content!.quickLinks.cta.link.indexOf('http') > -1
            "
            class="flex w-full items-center justify-between gap-3 py-2 text-left text-sm font-medium no-underline"
          >
            {{ menuData.content!.quickLinks.cta.name }}
            <IconArrowRight class="h-3.5 w-3.5 shrink-0 fill-green-600" />
          </NuxtLink>
        </li>
      </ul>
    </div>

    <button
      ref="closeButton"
      aria-label="Close navigation"
      class="sr-only"
      @click="$emit('close')"
    ></button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  menuData: NavChild;
  visible: boolean;
}>();

const emit = defineEmits(["back", "close"]);

// TODO – focus back button on page open?
const backButton = ref();
const closeButton = ref();

const firstFocusable = computed(() => backButton.value);
const lastFocusable = computed(() => closeButton.value);

const handleTab = (e: KeyboardEvent) =>
  restrictTab(e, firstFocusable.value, lastFocusable.value);
</script>

<style scoped>
* {
  touch-action: manipulation;
}
</style>

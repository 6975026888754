<template>
  <nav
    tabindex="-1"
    class="sidebar-container absolute right-0 top-full w-10/12 max-w-md overflow-x-hidden border-t border-grey-300 bg-grey-50 lg:hidden"
    @keydown.tab="handleTab"
  >
    <div class="relative h-full w-full">
      <button
        ref="closeButton"
        class="sr-only"
        aria-label="Close navigation"
        @click="$emit('close')"
      ></button>

      <div class="h-full overflow-y-scroll px-6 pb-8 pt-4">
        <ul class="mb-12 gap-0">
          <li
            v-for="(segmentContent, segmentID) in segmentData"
            :key="segmentID"
            class="border-t border-grey-300 first:!border-t-0"
          >
            <button
              :id="segmentID + 'MobileButton'"
              :aria-expanded="mobileAccordionsOpen[segmentID]"
              :aria-controls="segmentID + 'MobileMenu'"
              class="flex w-full items-center justify-between py-5 text-left text-xs font-bold uppercase italic tracking-wider"
              @click="handleAccordionClick(segmentID)"
            >
              <span>
                {{ segmentContent.nameAbbr ?? segmentContent.name }}
              </span>

              <svg
                class="h-3 w-3 shrink-0"
                viewBox="0 0 8 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  class="transition-opacity"
                  :class="
                    !mobileAccordionsOpen[segmentID]
                      ? 'opacity-100'
                      : 'opacity-0'
                  "
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 0.5C4.27614 0.5 4.5 0.723858 4.5 1L4.5 7C4.5 7.27614 4.27614 7.5 4 7.5C3.72386 7.5 3.5 7.27614 3.5 7L3.5 1C3.5 0.723858 3.72386 0.5 4 0.5Z"
                  fill="currentColor"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.5 4C0.5 3.72386 0.723858 3.5 1 3.5H7C7.27614 3.5 7.5 3.72386 7.5 4C7.5 4.27614 7.27614 4.5 7 4.5H1C0.723858 4.5 0.5 4.27614 0.5 4Z"
                  fill="currentColor"
                />
              </svg>
            </button>

            <transition name="accordion">
              <div
                v-show="mobileAccordionsOpen[segmentID]"
                :id="segmentID + 'MobileMenu'"
                :aria-labelledby="segmentID + 'MobileButton'"
                class="grid"
              >
                <ul class="gap-0 overflow-hidden">
                  <li
                    v-for="child in segmentContent.children"
                    :key="child.name"
                  >
                    <NuxtLink
                      v-if="child.link"
                      :to="child.link"
                      :external="isExternal(child.link)"
                      class="flex w-full items-center justify-start gap-3 py-3 text-left text-sm font-medium no-underline"
                    >
                      <component
                        :is="child.icon"
                        class="h-5 w-5 shrink-0 fill-green-800 text-green-800"
                      />
                      {{ child.name }}
                    </NuxtLink>

                    <template v-else>
                      <button
                        :id="child.id + 'MobileButton'"
                        :aria-expanded="mobileCurrentMenu === child.name"
                        :aria-controls="child.id + 'MobileMenu'"
                        class="flex w-full items-center justify-start gap-3 py-3 text-left text-sm font-medium no-underline"
                        @click="() => (mobileCurrentMenu = child.name)"
                      >
                        <component
                          :is="child.icon"
                          class="h-5 w-5 shrink-0 fill-green-800 text-green-800"
                        />

                        {{ child.name }}

                        <IconChevronRight
                          class="ml-auto mr-0 h-4 w-4 shrink-0 fill-grey-600"
                        />
                      </button>

                      <transition name="nested-page">
                        <NavSidebarNestedPage
                          :aria-labelledby="child.id + 'MobileButton'"
                          :visible="
                            mobileCurrentMenu === child.name &&
                            mobileAccordionsOpen[segmentID]
                          "
                          :menu-data="child"
                          @back="mobileCurrentMenu = ''"
                          @close="$emit('close')"
                        />
                      </transition>
                    </template>
                  </li>

                  <li class="my-8">
                    <NuxtLink
                      :to="segmentContent.link"
                      :external="isExternal(segmentContent.link)"
                      class="flex w-full items-center justify-start gap-1 text-sm font-medium no-underline"
                    >
                      See all
                      {{ segmentContent.nameAbbr ?? segmentContent.name }}
                      <IconArrowRight
                        class="h-3.5 w-3.5 shrink-0 fill-grey-600"
                      />
                    </NuxtLink>
                  </li>
                </ul>
              </div>
            </transition>
          </li>

          <li class="border-t border-grey-300">
            <button
              id="mobileLoginMenuButton"
              ref="loginButton"
              :aria-expanded="mobileCurrentMenu === loginData.name"
              aria-controls="mobileLoginMenu"
              :aria-pressed="mobileCurrentMenu === loginData.name"
              class="flex w-full items-center justify-between py-5 text-left text-xs font-bold uppercase italic tracking-wider"
              @click="() => (mobileCurrentMenu = loginData.name)"
            >
              {{ loginData.name }}
              <IconChevronRight class="h-4 w-4 shrink-0 fill-grey-600" />
            </button>

            <transition name="nested-page">
              <NavSidebarNestedPage
                id="mobileLoginMenu"
                aria-labelledby="mobileLoginMenuButton"
                :visible="mobileCurrentMenu === loginData.name"
                :menu-data="loginData"
                @back="mobileCurrentMenu = ''"
                @close="$emit('close')"
              />
            </transition>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
const props = defineProps<{ mobileIsOpen: boolean }>();

const emit = defineEmits(["close"]);
const { segmentData, loginData } = getNavData();
const closeButton = ref();
const loginButton = ref();
const firstFocusable = computed(() => closeButton.value);
const lastFocusable = computed(() => loginButton.value);
const mobileCurrentMenu = ref("");
const mobileAccordionsOpen = ref<{
  [key: string]: boolean;
}>({
  personal: false,
  business: false,
  enterprise: false,
  wholesale: false,
});

watch(
  () => props.mobileIsOpen,
  (newVal) => {
    if (!newVal) mobileCurrentMenu.value = "";

    for (const segment in mobileAccordionsOpen.value) {
      mobileAccordionsOpen.value[segment] = false;
    }
  },
);

const isExternal = (url?: string) =>
  url && url.indexOf("http") > -1 ? true : false;

const handleAccordionClick = (segment: string | number) => {
  mobileAccordionsOpen.value[segment] = !mobileAccordionsOpen.value[segment];
};

const handleTab = (e: KeyboardEvent) => {
  if (mobileCurrentMenu.value) return;
  restrictTab(e, firstFocusable.value, lastFocusable.value);
};
</script>

<style scoped>
* {
  touch-action: manipulation;
}

.sidebar-container {
  height: calc(100vh - 4rem) !important;
}

@supports (height: 100dvh) {
  .sidebar-container {
    height: calc(100dvh - 4rem) !important;
  }
}

.accordion-enter-active,
.accordion-leave-active {
  display: grid !important;
  transition-property: grid-template-rows, opacity;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  overflow: hidden;
  grid-template-rows: 1fr;
}

.accordion-enter-from,
.accordion-leave-to {
  grid-template-rows: 0fr;
  opacity: 0;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-enter-active,
  .accordion-leave-active,
  .nested-page-enter-active,
  .nested-page-leave-active {
    transition: none;
  }
}

.nested-page-enter-active,
.nested-page-leave-active {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.nested-page-enter-from,
.nested-page-leave-to {
  transform: translateX(100%);
}
</style>
